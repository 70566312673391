import { gql } from "@apollo/client";
import { KernelImage, ProjectLanguage } from "@hex/common";
import React from "react";

import { Picker } from "../common/Picker";

import { useGetKernelImagesForPickerQuery } from "./KernelImagePicker.generated";

gql`
  query GetKernelImagesForPicker($projectLanguage: ProjectLanguage!) {
    getDefaultKernelImage(projectLanguage: $projectLanguage)
    getKernelImages(projectLanguage: $projectLanguage) {
      id
      priority
      humanName
    }
  }
`;

interface KernelImagePickerProps {
  projectLanguage: ProjectLanguage;
  currentKernelImage: KernelImage | null;
  onSelect: (newKernelImage: KernelImage) => void;
  disabled?: boolean;
  hideLabel?: boolean;
  showLatest?: boolean;
}

export const KernelImagePicker: React.FunctionComponent<
  KernelImagePickerProps
> = ({
  currentKernelImage,
  disabled,
  hideLabel = false,
  onSelect,
  projectLanguage,
  showLatest = false,
}) => {
  const { data, error, loading } = useGetKernelImagesForPickerQuery({
    variables: {
      projectLanguage,
    },
  });

  const values =
    data?.getKernelImages
      .map((kernelImage) => ({
        title: kernelImage.humanName,
        key: kernelImage.id,
        priority: kernelImage.priority,
      }))
      .sort((a, b) => {
        return a.priority < b.priority ? -1 : a.priority > b.priority ? 1 : 0;
      }) ?? [];

  let selectedImage = currentKernelImage ?? data?.getDefaultKernelImage ?? null;
  if (showLatest) {
    values?.unshift({
      priority: 0,
      title: "Latest",
      key: "latest" as KernelImage,
    });
    selectedImage = currentKernelImage ?? ("latest" as KernelImage);
  }
  return (
    <Picker
      disabled={disabled}
      error={!!error}
      items={values}
      label={!hideLabel ? "Image" : ""}
      loading={loading}
      selectedItem={selectedImage}
      onSelect={onSelect}
    />
  );
};
